<template>
  <div>
    <el-button @click="dialogVisible = true">选择商品</el-button>
    <el-dialog custom-class="custom" append-to-body title="选择商品" show-close :visible.sync="dialogVisible" width="50rem">
      <div class="flex-def flex-zCenter" style="margin-bottom: 1rem">
        <el-input style="width: 40%" placeholder="输入商品名称" v-model="searchForm.title"></el-input>
        <el-button @click="search">搜索</el-button>
      </div>
      <el-table @row-click="toggleSelection([$event])" :data="list" border ref="multipleTable" @selection-change="handleSelectionChange">
        <el-table-column type="selection" prop="id" label="编号" width="60"></el-table-column>
        <el-table-column prop="pic" label="商品图" width="80">
          <template #default="s">
            <img class="flex-def" :src="s.row.pic | tomedia" style="width: 3rem;height: 3rem;border-radius: .5rem" alt="">
          </template>
        </el-table-column>
        <el-table-column prop="title" label="商品名称" show-overflow-tooltip></el-table-column>
        <el-table-column prop="fee" label="售价"></el-table-column>
        <el-table-column prop="cate" label="分类" show-overflow-tooltip>
          <template #default="s">
            {{ cateShow(s.row) }}
          </template>
        </el-table-column>
        <el-table-column :formatter="item=>item.status ? '已上架' : '未上架'" label="状态"></el-table-column>
        <template #append>
          <div class="flex-def flex-zBetween" style="padding: .5rem">
            <el-pagination  background layout="total,prev, pager,next"
                           :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
            </el-pagination>
          </div>
        </template>
      </el-table>
      <div slot="footer">
        <div class="flex-def flex-zCenter">
          <el-button @click="dialogVisible=false" style="width: 8rem">取消</el-button>
          <el-button @click="submit" style="width: 8rem" type="primary">确认</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {cateShow} from "@/view/goods/tools";

export default {
  name: "y_choose_goods",
  model:{
    prop:"modelVal",
    event:'change'
  },
  props:{
    modelVal: {
      type:Number,
      default:0
    },
    with_type: {
      type:Boolean,
      default: false,
    },
    type: {
      type:Number,
      default:0,
    },
  },
  watch:{
    modelVal(){
      this.value = this.modelVal;
    },
    value(e){
      this.$emit('change',e);
    }
  },
  data(){
    return{
      value: this.modelVal,
      dialogVisible:false,
      searchForm:{
        title:"",
      },
      list:[],
      page:1,
      total:0,
      multipleSelection:[],
    }
  },
  mounted() {
    this.load();
  },
  methods:{
    submit(){
      let ids = [];
      this.multipleSelection.forEach(item=>{
        ids.push(item.id)
      })
      this.$emit("choose-ids",ids);
      this.dialogVisible = false;
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    cateShow(row) {
      return cateShow(row);
    },
    pageChange(e) {
      this.page = e;
      this.load();
    },
    search(){
      this.page = 1;
      this.list.length = 0;
      this.load();
    },
    load() {
      this.$api.shop.goods.search({
        page: this.page,
        title: this.searchForm.title,
        with_type:this.with_type,
        type:this.type,
      }).then(res => {
        this.list = res.list;
        this.total = res.total;
      })
    },
  }
}
</script>
<style>
.custom>.el-dialog__body{
  padding: 0 20px 30px !important;
  margin: 0;
}
</style>