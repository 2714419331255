
export function parseGoodsType(type){
    let str = "";
    switch (parseInt(type)){
        case 0:
            str = "普通商品";
            break;
        case 1:
            str = "升级商品";
            break;
        case 2:
            str = "免费商品";
            break;
        case 3:
            str = "会员商品";
            break;
        case 4:
            str = "乡镇代理商品";
            break;
        case 5:
            str = "区县代理商品";
            break;
        case 6:
            str = "市级代理商品";
            break;
        case 7:
            str = "省级代理商品";
            break;
    }
    return str;
}
export function cateShow(row) {
    let p = row?.cate_parent?.title || ""
    let c = row?.cate?.title || ""
    return p + (p ? '/' : '') + c
}